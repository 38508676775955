import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload';
import throttle from 'lodash/throttle';
import Head from 'next/head'
import { useIsomorphicLayoutEffect } from '../../../adapters/helpers/Hooks';
import { getBackgroundImage, roundUpTheSize } from './BackgroundImage.helpers';
import { mediaQueryHOC } from '../../../adapters/helpers/Hooks'
import { ImageConstants } from '../../../adapters/helpers/Constants';

import ImageFactory from '../../../adapters/cloudinary/ImageFactory';
import { optimizeImage } from '../../../adapters/helpers/pagespeedHelper';

function BackgroundImage(props) {
    const mobileBackgroundImageId = props?.mobileBackgroundImage?.asset?.fields?.file?.url || props.contentImageId;
    const desktopBackgroundImageId = props?.desktopBackgroundImage?.asset?.fields?.file?.url || props.contentImageId;
    const {backgroundOffsetTop: marginTop, backgroundPosition, alt = null, children = null, customStyles = null, ariaHidden = false} = props;
    const paddingTop = `${ 100 / props.widthToHeightRatio }%`;
    const isPreload = props.isPreload ? props.isPreload : false;
    const isBannerImage = props.isBannerImage ? props.isBannerImage : false;
    const nodeRef = useRef(null);
    const placeholderRef = useRef(null);
    const [width, setWidth] = useState(ImageConstants.defaultImageWindWidth);
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [windowWidth, setWindowWidth] = useState(0);
    const [customClassName, setCustomClassName] = useState('');    
    const [url, setUrl] = useState(`url(${optimizeImage(ImageFactory.buildContentfullImageUrl(props.contentImageId))})`);
    function updateWidth() {
        const node = nodeRef.current || placeholderRef.current;
        if (node) {
            setWidth(roundUpTheSize(node.getBoundingClientRect().width));
        }
    }

    useEffect(() => {
        setIsPageLoaded(true);
        if (width != 0) {
            // Set background width*2 for mobile because the background is larger than the with parent
            setUrl(getBackgroundImage( props.contentImageId, isPreload && isBannerImage && window.innerWidth<400 ? 450  : props.isMedium ? width*2: width, props.transformations, props.format, props.isMedium));
        }
     }, [width, props.imageId, props.isMedium, isPageLoaded]);


    useEffect(
        () => {
            const handleResize = throttle(updateWidth, 100);
            setWindowWidth(window.innerWidth)
            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
                handleResize.cancel();
            };
        },
        []
    );

    useEffect(
        () => {
            setCustomClassName(props.customClassName);
        }, [props.customClassName]
    );

    useIsomorphicLayoutEffect(
        updateWidth,
        []
    );


    return (
        <React.Fragment>
            {isPreload && isBannerImage ?
                <React.Fragment>
                    <Head>
                        <link rel="preload" href={optimizeImage(ImageFactory.buildContentfullImageUrl(mobileBackgroundImageId))} as="image" media={`(max-width:${ImageConstants.mobile_max_width})`} />
                        <link rel="preload" href={optimizeImage(ImageFactory.buildContentfullImageUrl(desktopBackgroundImageId))} as="image" media={`(min-width:${ImageConstants.desktop_min_width})`} />
                    </Head>
                    {/* Below code structure have implemented for pagespeed. */}
                    {isPageLoaded ?
                        <div
                            className={`ob-background-wrapper ${windowWidth === 0 ? 'ob-background-wrapper-height' : ''} ${props.className ? props.className : ''} ${customClassName ? customClassName : ''}`}
                            ref={nodeRef}
                            aria-hidden={ariaHidden}
                            style={{
                                marginTop, paddingTop, backgroundPosition,
                                backgroundImage: `url(${optimizeImage(ImageFactory.buildContentfullImageUrl(props.contentImageId))})`,
                                ...customStyles
                            }}>
                            {alt &&
                                 <span role="img" aria-label={alt} aria-hidden='true' ></span>
                            }
                            {children}
                        </div>
                        :
                        <React.Fragment>
                            <div
                                className={`ob-background-wrapper ob-background-wrapper-mobile ${props.className ? props.className : ''} ${customClassName ? customClassName : ''}`}
                                ref={nodeRef}
                                aria-hidden={ariaHidden}
                                style={{
                                    marginTop, paddingTop, backgroundPosition,
                                    backgroundImage: `url(${optimizeImage(ImageFactory.buildContentfullImageUrl(mobileBackgroundImageId))})`,
                                    ...customStyles
                                }}>
                                {alt &&
                                    <span role="img" aria-label={alt}></span>
                                }
                                {children}
                            </div>
                            <div
                                className={`ob-background-wrapper ob-background-wrapper-desktop ${windowWidth === 0 ? 'ob-background-wrapper-height' : ''} ${props.className ? props.className : ''} ${customClassName ? customClassName : ''}`}
                                ref={nodeRef}
                                aria-hidden={ariaHidden}
                                style={{
                                    marginTop, paddingTop, backgroundPosition,
                                    backgroundImage: `url(${optimizeImage(ImageFactory.buildContentfullImageUrl(desktopBackgroundImageId))})`,
                                    ...customStyles
                                }}>
                                {alt &&
                                    <span role="img" aria-label={alt} aria-hidden='true' ></span>
                                }
                                {children}
                            </div>
                        </React.Fragment>
                    }
                </React.Fragment>
            :   <LazyLoad offset={500}
            placeholder={
                <div
                    className={`ob-background-wrapper ${props.className ? props.className : ''} ${customClassName}`}
                    ref={placeholderRef}
                    aria-hidden={ariaHidden}
                    style={{
                        marginTop, paddingTop, backgroundPosition
                    }}
                />
            }
        >
            <div
                className={`ob-background-wrapper ${props.className ? props.className : ''} ${customClassName}`}
                ref={nodeRef}
                aria-hidden={ariaHidden}
                style={{
                    marginTop, paddingTop, backgroundPosition,
                    backgroundImage: ((!props.isLarge && !props.isMedium) || (props.isLarge && !props.isMedium)) ? `url(${optimizeImage(ImageFactory.buildContentfullImageUrl(desktopBackgroundImageId))})` : `url(${optimizeImage(ImageFactory.buildContentfullImageUrl(mobileBackgroundImageId))})`,
                    ...customStyles
                }}>
                {alt &&
                     <span role="img" aria-label={alt} aria-hidden='true'></span>
                }
                {children}
            </div>
        </LazyLoad>}
        </React.Fragment>
      
    );
}

export default mediaQueryHOC(BackgroundImage);

BackgroundImage.defaultProps = {
    transformations: 'c_limit',
    fragments: [{ minWidth: 0, size: 1 }],
    widthToHeightRatio: 1
}

BackgroundImage.propTypes = {
    imageId: PropTypes.string,
    transformations: PropTypes.string,
    className: PropTypes.string,
    customClassName: PropTypes.string,
    fragments: PropTypes.array,
    widthToHeightRatio: PropTypes.number,
    children: PropTypes.node,
    backgroundOffsetTop: PropTypes.string,
    backgroundPosition: PropTypes.string,
    customStyles: PropTypes.object,
    format: PropTypes.any,
    isMedium: PropTypes.bool,
    ariaHidden: PropTypes.bool,
    alt: PropTypes.string,
    isPreload: PropTypes.bool,
    isBannerImage : PropTypes.bool,
    preloadImage: PropTypes.object,
    desktopBackgroundImage: PropTypes.any,
    mobileBackgroundImage: PropTypes.any
}

